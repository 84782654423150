import React, { useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

import * as webSocketActions from '../../../redux/actions/webSocketActions';

import { Button } from '@mui/material';

import LightbulbIcon from '@mui/icons-material/Lightbulb';
import PaidIcon from '@mui/icons-material/Paid';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import TOC from '../../../components/TOC';
import SCLandingWrapper from '../../SCLandingWrapper';
import FindPlansLink from '../FindPlansLink';
import ZipCodeField from '../ZipCodeField';
import ThousandKWhTrap from '../../LandingSC/ThousandKWhTrap';

import '../index.css';
import './index.css';

function Article({ siteConfig, serviceAddress }) {
  const [showTrap, setShowTrap] = useState(null);
  return (
    <div className="article-container">
      <ZipCodeField mobile buttonLabel={'Compare Fixed Rates'} />

      <h1>
        <div>Choosing the Right Fixed Rate Electric Plan</div>
      </h1>
      <div className="page-container text-container fixed-rates">
        <h5 className="est-read-time">
          Published: 03/20/2023 | Read time {'< '}5 min
        </h5>

        <div className="page">
          <div className="inner">
            <TOC>
              <ZipCodeField
                intro={'Quickly find a fixed electric rate for your home:'}
              />
            </TOC>
            <div className="article-text">
              <h2>Introduction</h2>
              <p>
                As a resident of Texas, you have the power to choose your
                electricity provider and plan. One popular option is the fixed
                rate electric plan, which can provide stability and
                predictability in your monthly electric bill if you choose the
                right plan for your usage level.
              </p>
              <p>
                With so many options available, it can be easy to pick what
                seems to be the lowest rate and then end up paying more than you
                thought you would. In this article, we'll go over the common
                "gotchas" that come up when choosing this type of rate, and
                we'll explain the best way to find a fixed rate that won't end
                up costing you more than you expected.
              </p>
              <h2>What is a Fixed Rate Electric Plan? </h2>
              <div className="parallax-image">
                <ParallaxProvider>
                  <Parallax speed={-10}>
                    <LightbulbIcon className="top-icon" />
                  </Parallax>
                </ParallaxProvider>
              </div>
              <p>
                A fixed rate electric plan is a type of electricity plan where
                the rate you pay for your electricity usage remains the same
                throughout the contract term. This means that the price per
                kilowatt-hour (kWh) you pay for electricity will not fluctuate,
                regardless of changes in the wholesale electricity market or
                weather patterns.
              </p>
              <p>
                Your bill will depend on how much electricity you use, but the
                rate you pay will remain constant.
              </p>
              <h2>Fixed Rate "Gotchas"</h2>
              <p>
                While a fixed rate plan sounds simple enough, some of these
                electric plans still have built in usage tiers or usage-based
                credit discounts that can drastically affect your monthly bill
                if your actual electric usage needs do not match the plan
                structure.
              </p>
              <p>
                To achieve a lower advertised price, some providers offer fixed
                rate plans that have usage-based pricing tiers or bill credits.
                While these are still technically fixed rate plans, you need to
                carefully decide if the plan fits into your electricity usage
                patterns.
              </p>
              <p>
                If the fixed rate plan you choose has an advertised price that
                is achieved through a discount that only applies if you use more
                electricity than you actually do, the discount may never kick
                in, which means you would not receive the lower rate that was
                advertised. This is what we call the{' '}
                <a
                  href={'/rescue'}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setShowTrap(!showTrap);
                  }}
                  className="link">
                  <strong>
                    {' '}
                    1,000-kilowatt Trap{' '}
                    <KeyboardArrowDownIcon
                      style={{
                        transform: showTrap ? 'rotate(180deg)' : 'none'
                      }}
                    />
                  </strong>
                </a>
              </p>
              {showTrap ? (
                <div className="sub-article">
                  <div className="page-container text-container full sc-landing">
                    <div className="page">
                      <div className="inner">
                        <ThousandKWhTrap />
                      </div>
                    </div>
                  </div>
                  <Button
                    onClick={(event) => {
                      setShowTrap(false);
                    }}
                    className="close"
                    variant="outlined">
                    <strong>
                      Close
                      <KeyboardArrowDownIcon
                        style={{
                          transform: showTrap ? 'rotate(180deg)' : 'none'
                        }}
                      />
                    </strong>
                  </Button>
                </div>
              ) : null}
              <h2>Benefits of a Fixed Rate Electric Plan </h2>
              <p>
                If you choose a fixed rate plan that does not have a tiered
                structure or you decide that a tiered fixed rate works for your
                usage patterns, a fixed rate electric plan offers several
                benefits to customers, including:
              </p>
              <ul>
                <li>
                  <strong>Budget predictability:</strong> With a fixed rate
                  plan, you can easily predict your monthly electricity bill,
                  which can help you plan and budget your expenses.
                </li>
                <li>
                  <strong>Protection against price spikes:</strong> If
                  electricity prices suddenly increase due to market factors or
                  weather patterns, you will be protected from paying higher
                  rates because your rate is fixed.
                </li>
                <li>
                  <strong>Long-term savings: </strong>If you lock in a low rate,
                  you can save money over time compared to customers who choose
                  variable rate plans.
                </li>
                <li>
                  <strong>Peace of mind:</strong> With a fixed rate plan, you
                  don't have to worry about your bill increasing unexpectedly,
                  which can provide peace of mind and reduce stress.
                </li>
              </ul>
              <h2>
                Things to Consider When Choosing a Fixed Rate Electric Plan{' '}
              </h2>
              <p>
                Before choosing a fixed rate electric plan, there are a few
                things to consider:
              </p>{' '}
              <div className="parallax-image">
                <ParallaxProvider>
                  <Parallax speed={-10}>
                    <PaidIcon className="bottom-icon" />
                  </Parallax>
                </ParallaxProvider>
              </div>
              <ul>
                <li>
                  <strong>Contract length:</strong> Fixed rate plans typically
                  require a contract commitment, ranging from 6 months to 3
                  years. Make sure you choose a contract length that works for
                  you.
                </li>
                <li>
                  <strong>Early termination fees:</strong> If you decide to
                  cancel your contract early, you may be subject to early
                  termination fees. Make sure you understand the fees associated
                  with your plan.
                </li>
                <li>
                  <strong>Electricity usage:</strong> A fixed rate plan may not
                  be the best option if you have highly variable electricity
                  usage, as your bill will still vary based on usage.
                </li>
                <li>
                  <strong>Usage-based discounts:</strong> Check if the rate your
                  choosing has a single rate for all usage or if you have to use
                  a certain amount of electricity to benefit from usage-based
                  discounts.
                </li>
                <li>
                  <strong>Market trends:</strong> While a fixed rate plan
                  protects you from sudden price increases, it may not always
                  offer the best value if wholesale electricity prices are low.
                </li>
              </ul>
              <h2>Choosing the Right Fixed Rate Plan</h2>
              <FindPlansLink
                planType={'simple fixed rate'}
                text={
                  <>
                    <p>
                      To find your best overall value you should probably avoid
                      plans that use 'bill credits' as a pricing gimmick to make
                      their advertised prices look lower than they really are.
                      The simple fixed rate plans below offer you low prices at
                      all usage levels without gimmicks.
                    </p>
                  </>
                }
              />
            </div>
          </div>
        </div>
      </div>
      <SCLandingWrapper
        city={'dallas'}
        noHeader
        preFilter={{ quickFilter: 'simple-fixed' }}
        preFilterLabel="fixed rate"
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    siteConfig: state.commonData.siteConfig,
    serviceAddress: state.serviceAddress
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Article);
