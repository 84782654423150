import React from 'react';
import SEO from '../components/SEO';

import Article from '../components/Articles/Fixed-Rates/index';

export default function ({}) {
  return (
    <>
      <SEO
        title={'Choosing the Right Fixed Rate Electric Plan'}
        description={
          'Is a fixed rate electric plan right for you? While these plans can provide stability and predictability in your monthly electricity bills, choosing the wrong one for your usage level can have the opposite effect on your monthly bill.'
        }
        brand={'sc'}
      />

      <Article />
    </>
  );
}
